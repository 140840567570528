import React from 'react';

import {Link} from "gatsby"

const FreeTrialButton = () => (
	<div className="mt-5 max-w-full mx-auto sm:flex flex-col md:flex-row justify-left md:mt-8">
      <div className="inline-flex rounded-md shadow">
        <Link to="/demo" className="w-full flex items-center justify-center px-8 py-3 border border-highlight-500 text-base leading-6 font-medium rounded-md text-highlight-500 bg-white hover:bg-white-500 focus:outline-none focus:shadow-outline-highlight transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Book a demo
        </Link>
      </div>
      <div className="mt-4 md:mt-0 md:ml-8 inline-flex rounded-md shadow">
        <a href="https://app.timekeeper.co.uk/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-600 hover:bg-highlight-500 focus:outline-none focus:shadow-outline-highlight transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
          Start your 14 day free trial
        </a>
      </div>
    </div>
)

export default FreeTrialButton;
