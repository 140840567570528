import React from 'react';

import abaxElectricalSrc from '../images/logos/abax-electrical.svg';
import kglackinSrc from '../images/logos/kglackin-electrics.svg';
import jandbEngineeringSrc from '../images/logos/j_and_b_engineering.svg';

const Testimonial = ({name, jobTitle, companyName, speech, companyImageSrc, companyUrl}) => {
	return (
		<div className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
		  <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
		    <svg className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
		      <defs>
		        <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
		          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
		        </pattern>
		      </defs>
		      <rect width="404" height="404" fill="url(#svg-pattern-squares-1)" />
		    </svg>

		    <div className="relative cursor-pointer">
		      <a href={companyUrl} target="_blank" rel="noopener noreferrer">
			      <img
		            className="mx-auto h-16"
		            src={companyImageSrc}
		            alt={companyName}
		          />
		      </a>
		      <blockquote className="mt-8">
		        <div className="max-w-3xl mx-auto text-center text-2xl italic leading-9 font-medium text-gray-900">
		           "{speech}"
		        </div>
		        <footer className="mt-8">
		          <div className="md:flex md:items-center md:justify-center">
		            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
		              <div className="text-base leading-6 font-medium text-gray-900">{name}</div>

		              <svg className="hidden md:block mx-1 h-5 w-5 text-highlight-600" fill="currentColor" viewBox="0 0 20 20">
		                <path d="M11 0h3L9 20H6l5-20z" />
		              </svg>

		              <div className="text-base leading-6 font-medium text-gray-500">{jobTitle}, {companyName}</div>
		            </div>
		          </div>
		        </footer>
		      </blockquote>
		    </div>
		  </div>
		</div>
	)
}


const KGlackinTestimonial = () => {
	return <Testimonial
		name="Kieran Glackin"
		jobTitle="Managing Director"
		companyName="Glackin Electrics"
		speech="We are more than happy with TimeKeeper as it is doing a brilliant job managing our time and attendance for workers on site."
		companyImageSrc={kglackinSrc}
		companyUrl="https://www.glackinelectrics.co.uk"
	/>
}

const AbaxElectricalTestimonial = () => {
	return <Testimonial
				name="Neil Frew"
				jobTitle="Contracts Manager"
				companyName="ABax Electrical"
				speech="We have found TimeKeeper very useful to our business, not only does it cut out hours of administration with timesheets & holiday pay, but it also increases productivity on our sites."
				companyImageSrc={abaxElectricalSrc}
				companyUrl="https://abaxelectricalltd.co.uk"
	 />
}

const LaganEngineeringTestimonial = () => {
	return <Testimonial
				name="Brian Lagan"
				jobTitle="Managing Director"
				companyName="Lagan Engineering"
				speech="The ease of use and flexibility within the software for our administrators and payroll department plus the tight security and protection from misuse with our employees means we have real peace of mind in our timekeeping, something that we had struggled with for years."
				companyImageSrc={jandbEngineeringSrc}
				companyUrl="https://www.jandbengineering.co.uk"
			/>
}

export default Testimonial;

export {
	KGlackinTestimonial,
	AbaxElectricalTestimonial,
	LaganEngineeringTestimonial
}
