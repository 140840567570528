import React from 'react';

import { Link } from 'gatsby';
import { ArrowRight } from './ui';

const LeftFeature = ({sectionTitle, title, body, imgSrc, imgAlt, link, includeShadow=false}) => {
	let imgClassName = "mt-10 -mx-4 relative lg:mt-0";
  if(includeShadow){
    imgClassName = `${imgClassName} shadow-xl`;
  }
  return (
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <div className="text-highlight-500 text-base font-bold mb-4">
        	{sectionTitle}
        </div>
        <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-5xl sm:leading-snug">
          {title}
        </h4>
        {body}
        {link != null && 
          <div className="flex items-center mt-3">
          	<Link to={link} 
          		className="text-gray-500 font-extrabold hover:underline hover:text-highlight-500 focus:underline">Learn More</Link>
            	<ArrowRight />
          </div>
        }
      </div>

      <div className={imgClassName}>
        <img className="relative mx-auto" src={imgSrc} alt={imgAlt} />
      </div>
    </div>
  )
}

export default LeftFeature;