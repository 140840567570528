import React from 'react';

const ArrowRight = () => (
	<div className="inline-flex ml-2 text-gray-500 justify-center items-center cursor-pointer hover:underline hover:text-highlight-500">
      		<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
      	</div>
)

export {
	ArrowRight
}