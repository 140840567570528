import React from 'react';

import {Link} from "gatsby";

const FreeTrial = ({part1 = null, part2 = null, includeDemo=true, showDemoBooking=false}) => {
	let renderPart1 = part1 != null ? part1 : (<span>Ready to save your <br /> business time and money?</span>);
	let renderPart2 = part2 != null ? part2 : (<span>Start your 14 day free trial</span>)

	return (
		<div className="bg-primary-500">
		  <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
		    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
		      {renderPart1}
		      <br />
		    </h2>
		    <div className="mt-8 flex justify-center">
		      <div className="inline-flex rounded-md shadow">
		        <a href="https://app.timekeeper.co.uk/register" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-600 hover:bg-highlight-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
		        	{renderPart2}
		        </a>
		       </div>
		       {includeDemo &&
		       	<div className="inline-flex rounded-md shadow">
			        <Link to='/demo' className="ml-8 inline-flex items-center justify-center px-5 py-3 border border-transparent text-highlight-600 leading-6 font-medium rounded-md text-white bg-white hover:bg-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
			        	<span>Book a demo</span>
			        </Link>
			      </div>
			    }
		    </div>
		  </div>
		</div>
	)
}

export default FreeTrial;
