import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {KGlackinTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import cameraSrc from '../images/camera.svg';
import fileSyncSrc from '../images/file-sync.svg';
import tabletMultipleEmployeesSrc from '../images/tablet-multiple-employees.svg';
import mobileGpsSrc from '../images/mobile-gps.svg';
import reportSrc from '../images/report.svg';
import constructionSrc from '../images/construction.svg'
import geofenceSrc from '../images/geofence.png';
import mapMultipleSrc from '../images/map-multiple.svg';
import calendarSrc from '../images/calendar.svg';

import FreeTrialButton from '../components/free-trial-button';

const ConstructionTimesheetApp = () => (
  <Layout>
    <SEO 
    	title="The Best Timesheet App for Construction Companies"
    	description="The best timesheet software for construction companies. Track time spent on jobs with optional geofences for building sites."
    	 />
    <Navbar />
    <div className="bg-gray-50">
	  <div className="relative bg-white overflow-hidden">
		  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
		    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
		      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
		        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
		          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
		            The best timesheet app for
		            <br />
		            <span className="text-highlight-600">construction companies</span>
		          </h2>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		            TimeKeeper is built to suit construction companies where workers are on site or travelling.
		          </p>
		          <FreeTrialButton />
		        </div>
		        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
		          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
		            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
		              <img className="w-full" src={constructionSrc} alt="Construction Workers on Site" />
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
		  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				<LeftFeature 
			    	sectionTitle="Digital audit trail & facial recognition" 
			    	title="Accurate, verified timesheets" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          With paper timesheets, employees can easily fool the system by writing down whatever hours suit them.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	Well with TimeKeeper, this problem is eliminated with timestamped clock-ins and the optional use of facial recognition to verify every 
					        	clock in is legit.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={cameraSrc}
			    	imgAlt="Photograph being lifted to show facial recognition" 
			    />
				<RightFeature 
			    	sectionTitle="Portable clock in machine" 
			    	title="Time clock for building sites" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					        	If you have multiple workers on a building site, you can use TimeKeeper Kiosk on an Android or iOs tablet for all of them to clock in through one device.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Fix the tablet to the wall for security and then when moving to the next building site, just take the tablet and wall mount with you.
					        </FeaturePararaph>
					        <AppStoreIcons />
					    </div>
			    	}
			    	imgSrc={tabletMultipleEmployeesSrc}
			    	imgAlt="Tablet for Multiple Employees to Clock In"
			    />
			    <LeftFeature 
			    	sectionTitle="Perfect for travelling workers" 
			    	title="Mobile GPS clock ins" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Perhaps your workers are travelling out to individual jobs - well workers can use the TimeKeeper mobile app with their 
					          own account.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          All mobile clock-ins require GPS so you can always have their location when clocking in from.
					        </FeaturePararaph>
					        <AppStoreIcons />
					    </div>
			    	}
			    	imgSrc={mobileGpsSrc}
			    	imgAlt="GPS Location on Mobile App Clock In"
			     />
			     <RightFeature 
			    	sectionTitle="Defined areas where staff can clock in and out" 
			    	title="Geofence clock entries" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          TimeKeeper enables you to configure geofences for jobs so only an employee at that location can clock in or out.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Simply specify an address for a job and a distance that the employee must be within and we will handle the rest.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={geofenceSrc}
			    	imgAlt="Geofence Areas on Clock In"
			     />
			     <LeftFeature 
			    	sectionTitle="Live site list" 
			    	title="Who's in on each site at all times" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          Do you want to know if your employees are where they are supposed to be?
					        </FeaturePararaph>
					        <FeaturePararaph>
					          TimeKeeper keeps a live list of each worker on each building site so you don't have to call the 
					          site manager to check if an employee is in.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={mapMultipleSrc}
			    	imgAlt="Markers of who is in on each building site"
			    />
			    <RightFeature 
			    	sectionTitle="Managing your jobs" 
			    	title="Track time spent on jobs" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					        	You can add your jobs to TimeKeeper which employees can clock in against.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          We will automatically keep track of time on each job for you so you can see if the time spent on that job 
					          has been profitable.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={reportSrc}
			    	imgAlt="Tracking time on jobs report"
			    />
			    <LeftFeature 
			    	sectionTitle="Communicate with your employees in real time" 
			    	title="Photos, documents, notes and signatures for jobs" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					         Staff in your HQ can attach files to a job that your workers can see instantly via our app.
					        </FeaturePararaph>
					        <FeaturePararaph>
					          Additionally workers can snap photographs, add notes and add signatures to a job that you can view back in HQ - all in real time.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={fileSyncSrc}
			    	imgAlt="Share documents for jobs in real time"
			    />
			    <RightFeature 
			    	sectionTitle="Keep your employees on the right job" 
			    	title="Integrated job scheduler" 
			    	body={
			    		<div>
				    		<FeaturePararaph>
					        	Are you constantly sending messages telling workers what job they are to meant work on?
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	With TimeKeeper, you can schedule your jobs in our web portal and we'll automatically notify your employees via a push notification of changes to their schedule.
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={calendarSrc}
			    	imgAlt="Schedule Jobs"
			    />
			</div>
			<KGlackinTestimonial />
			<FreeTrial includeDemo={true} part1={<span>Ready to save your <span className="text-highlight-600">construction</span> <br /> business thousands?</span>} />
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default ConstructionTimesheetApp
